<template>
  <div class="operationTable">
    <comp-table :tableData="tableData" :tableHeader="tableHeader" :pageSize='pageSize' :total='total' @current-events="getList" @save-current="savePage"
      v-loading='isLoading'
      element-loading-text="拼命加载中"
      element-loading-customClass='el-icon-loading'
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.7)"
      v-if="initStatus" :expand='false'
      @size-events="changeSize">
    </comp-table>
    <nodata :message="'暂无相关日志'" h="400" v-else />
  </div>
</template>

<script>
  import moment from 'moment'
  import axios from "axios";
  export default {
    props: ['value','dateVlaue','userId'],
    name: "operationTable",
    components: {
    },
    data() {
      return {
        tableData: [], // 请求到的表格数据
        tableHeader: [
          // 表头信息
          {
            prop: "phone",
            center: 'left',
            label: "账号",
          },
          {
            prop: "userName",
            label: "用户姓名",
          },
          {
            prop: "operation",
            label: "操作内容",
          },
          {
            prop: "createTime",
            label: "操作时间",
          },
          {
            prop: "respFlag",
            label: "操作结果",
          },
          {
            prop: "attachmentFlag",
            label: "错误数据",
            operation:
                [
                  { downloadLog : true , name: "下载",  clickFun: this.doDownloadLog}
                ]
          },
        ],
        multipleSelection: [],

        //分页查询
        page: 1,
        data: [],
        total: 0,
        pageSize: 20,
        current: '',
        isLoading: false,
        initStatus : false,
      };
    },
    methods: {
      changeSize(size) {
        this.pageSize = size
        this.getList()
      },
      // 所有实例列表
      savePage(val) {
        this.current = val
      },
      doDownloadLog(item){
        this.rowData = item;

        this.uploadError(this.rowData.filePath);


      },
      uploadError(url){
        this.isdeling = true
        axios({
          method: 'get',
          url: '/api/euser/log/downloadAttachment',
          responseType: 'blob',
          params: {
            logId : val
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '错误日志' + now + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
        }).catch(err=>{
          this.isdeling = false
          //einvAlert.error("提示",err)
        })
      },
      getList(val) {
        this.isLoading = true
        this.$store.dispatch("FindLogList", {
          msg: this.value, //模糊查询
          companyId:  this.getToken('companyId'), //公司Id
          userId: this.userId, //用户ID
          page: val ? val : this.page,
          rows: this.pageSize,

          timeStart : this.dateVlaue!=null ? this.dateVlaue[0]: null,
          timeEnd : this.dateVlaue!=null ? this.dateVlaue[1] : null,
        }).then(res => {
          this.isLoading = false
          if (res.success) {
              this.initStatus = true;
              this.total = res.totalCount;
              this.tableData = res.data;
          } else {
            this.initStatus = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },

      sortEvents(type) {
        console.log(type);
      },
    },
    mounted() {


    },
  };
</script>

<style scoped>
  .operationTable{
    height: 100%;
    background: #fff;
  }
</style>
